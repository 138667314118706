<template>
    <a-modal :width="formData.isImport == 0 ? 610 : 800" :maskClosable="false" :title="formData.id ? '编辑' : '新增'"
        :visible="visible" @cancel="handleCancel" @ok="handleSubmit" :confirm-loading="confirmLoading" okText="提交审核">
        <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-row>
                <a-col>
                    <!-- 商品资质 -->
                    <a-form-model-item required label="商品编码" :labelCol="{ span: 4 }"
                        :wrapperCol="{ span: 18, offset: 1 }">
                        <a-input placeholder="请输入商品编码" :disabled="formData.id" v-model="formData.name"></a-input>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col>
                    <a-form-model-item required label="是否进口商品" :labelCol="{ span: 5 }"
                        :wrapperCol="{ span: 18, offset: 1 }">
                        <a-radio-group v-model="formData.isImport" :disabled="formData.id" @change="isImportChange">
                            <a-radio :value="0">否</a-radio>
                            <a-radio :value="1">是</a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <div style="display: flex;">
                <a-card v-if="formData.isImport == 0" title="产品质检报告"
                    :style="{ width: formData.isImport == 1 ? '50%' : '100%' }">
                    <a-row>
                        <a-col>
                            <a-form-model-item required label="文件" :labelCol="{ span: 4 }"
                                :wrapperCol="{ span: 18, offset: 1 }">
                                <a-upload name="file" :accept="'.pdf,.png,.jpg,.jpeg,pdf'" :multiple="false"
                                    v-if="!oneObj.fileUrl" :action="qiNiuAction" :data="postData"
                                    @change="handleChange">
                                    <a-button>
                                        <a-icon type="upload" /> 上传文件
                                    </a-button>
                                </a-upload>
                                <div v-else>
                                    <a :href="oneObj.fileUrl" style="margin-left: 5px;" target="_blank">预览文件</a>
                                    <a @click="removeFile(0)" style="margin-left: 5px;color:red;">删除</a>
                                </div>
                                <!-- <a v-if="oneObj.id && oneObj.fileUrl" :href="oneObj.fileUrl" target="_blank">预览文件</a>
                                <a-upload name="file" :accept="'.pdf,.png,.jpg,.jpeg'" :multiple="false" 
                                    :file-List="fileList" :action="qiNiuAction" :data="postData" @change="handleChange">
                                    <a-button v-if="!oneObj.fileUrl"> <a-icon type="upload" /> 上传产品质检报告 </a-button>
                                </a-upload>
                                <a @click="removeFile(0)" style="margin-left: 5px;color:red;"
                                    v-if="oneObj.id && oneObj.fileUrl" target="_blank">删除</a> -->
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-form-model-item label="生效时间" required :labelCol="{ span: 5 }"
                            :wrapperCol="{ span: 18, offset: 1 }">
                            <a-date-picker v-model="dateStartTime" @change="onStartChange" />
                        </a-form-model-item>
                    </a-row>
                    <a-row>
                        <a-form-model-item label="失效时间" required :labelCol="{ span: 5 }"
                            :wrapperCol="{ span: 18, offset: 1 }">
                            <a-date-picker style="margin-right: 10px;" v-if="!langTime" v-model="dateEndTime"
                                @change="onEndChange" />
                            <!-- <a-checkbox v-model="langTime" @change="onLangTime">长期有效</a-checkbox> -->
                        </a-form-model-item>
                    </a-row>
                </a-card>
                <a-card v-if="formData.isImport == 1" title="入境检验检疫证明"
                    :style="{ width: formData.isImport == 1 ? '50%' : '100%' }">
                    <a-row>
                        <a-col>
                            <a-form-model-item label="文件" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
                                <a-upload name="file" :accept="'.pdf,.png,.jpg,.jpeg,pdf'" :multiple="false"
                                    v-if="!oneObj.fileUrl" :action="qiNiuAction" :data="postData"
                                    @change="handleChange">
                                    <a-button>
                                        <a-icon type="upload" /> 上传文件
                                    </a-button>
                                </a-upload>
                                <div v-else>
                                    <a :href="oneObj.fileUrl" style="margin-left: 5px;" target="_blank">预览文件</a>
                                    <a @click="removeFile(0)" style="margin-left: 5px;color:red;">删除</a>
                                </div>
                                <!-- <a v-if="oneObj.id && oneObj.fileUrl" :href="oneObj.fileUrl" target="_blank">预览文件</a>
                                <a-upload v-if="!oneObj.fileUrl" name="file" :accept="'.pdf,.png,.jpg,.jpeg'"
                                    :multiple="false" :file-List="fileList" :action="qiNiuAction" :data="postData"
                                    @change="handleChange">
                                    <a-button v-if="!oneObj.fileUrl"> <a-icon type="upload" /> 上传入境检验检疫证明 </a-button>
                                </a-upload>
                                <a @click="removeFile(0)" style="margin-left: 5px;color:red;"
                                    v-if="oneObj.id && oneObj.fileUrl" target="_blank">删除</a> -->
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-form-model-item label="生效时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                            <a-date-picker v-model="dateStartTime" @change="onStartChange" />
                        </a-form-model-item>
                    </a-row>
                    <a-row>
                        <a-form-model-item label="失效时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                            <a-date-picker style="margin-right: 10px;" v-model="dateEndTime" @change="onEndChange" />
                            <!-- <a-checkbox v-model="langTime" @change="onLangTime">长期有效</a-checkbox> -->
                        </a-form-model-item>
                    </a-row>
                </a-card>

                <a-card v-if="formData.isImport == 1" title="报关单" style="width: 50%">
                    <a-row>
                        <a-col>
                            <a-form-model-item required label="文件" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
                                <a-upload name="file" :accept="'.pdf,.png,.jpg,.jpeg,pdf'" :multiple="false"
                                    v-if="!twoObj.fileUrl" :action="qiNiuAction" :data="postData"
                                    @change="handleTwoChange">
                                    <a-button>
                                        <a-icon type="upload" /> 上传文件
                                    </a-button>
                                </a-upload>
                                <div v-else>
                                    <a :href="twoObj.fileUrl" style="margin-left: 5px;" target="_blank">预览文件</a>
                                    <a @click="removeFile(1)" style="margin-left: 5px;color:red;">删除</a>
                                </div>
                                <!-- <a v-if="twoObj.id && twoObj.fileUrl" :href="twoObj.fileUrl" target="_blank">预览文件</a>
                                <a-upload name="file" :accept="'.pdf,.png,.jpg,.jpeg'" :multiple="false"
                                    :file-List="fileList2" :action="qiNiuAction" :data="postData"
                                    @change="handleTwoChange" v-if="!twoObj.fileUrl">
                                    <a-button v-if="!twoObj.fileUrl"> <a-icon type="upload" /> 上传报关单 </a-button>


                                </a-upload>
                                <a @click="removeFile(1)" style="margin-left: 5px;color:red;"
                                    v-if="twoObj.id && twoObj.fileUrl" target="_blank">删除</a> -->
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-form-model-item required label="生效时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                            <a-date-picker v-model="dateStartTime2" @change="onStartChangeTwo" />
                        </a-form-model-item>
                    </a-row>
                    <a-row>
                        <a-form-model-item required label="失效时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                            <a-date-picker style="margin-right: 10px;" v-model="dateEndTime2"
                                @change="onEndChangeTwo" />
                            <!-- <a-checkbox v-model="langTimeTwo" @change="onLangTimeTwo">长期有效</a-checkbox> -->
                        </a-form-model-item>
                    </a-row>
                </a-card>
            </div>



        </a-form-model>
    </a-modal>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import { selectCitymixins } from '@/mixin/selectCity'
import { publicMixin } from '@/mixin/public';
export default {
    mixins: [selectCitymixins, publicMixin],
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        confirmLoading: {
            type: Boolean,
            required: true
        },
        formData: {
            type: Object,
            required: true
        },
        form: {
            type: Object,
            required: true
        },
        pageType: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            moment,
            labelCol: { span: 4 },
            wrapperCol: { span: 24 },
            fileList: [],
            fileList2: [],
            qiNiuAction: 'https://upload-z1.qiniup.com',
            qiNiuUploadData: {
                key: '',
                token: ''
            },
            getQiniuTokenIng: false,
            postData: {
                token: ''
            },
            dateStartTime: "",
            dateEndTime: "",
            dateStartTime2: "",
            dateEndTime2: "",
            langTime: false,//长期有效
            langTimeTwo: false,
            oneObj: {},
            twoObj: {},
            manuList: [{ id: 301, name: '生产商营业执照' }, { id: 302, name: '食品生产许可证' }],
            categoryList: [{ id: 101, name: '营业执照' }, { id: 102, name: '一般纳税人证明' }, { id: 103, name: '基本开户信息表' }, { id: 104, name: '食品经营许可证' }]
        }
    },
    mounted() {
        if (this.formData.id) {
            this.formData.qualificationFileList.forEach(item => {
                if (item.certificateType == 401) {
                    this.oneObj = item
                    this.dateStartTime = moment(item.startTime)
                    this.dateEndTime = moment(item.endTime)
                    if (item.endTime == '4070880000000') {
                        this.langTime = true;
                    }
                }
                if (item.certificateType == 403) {
                    this.oneObj = item
                    this.dateStartTime = moment(item.startTime)
                    this.dateEndTime = moment(item.endTime)
                    if (item.endTime == '4070880000000') {
                        this.langTime = true;
                    }
                }
                if (item.certificateType == 402) {
                    this.twoObj = item
                    this.dateStartTime2 = moment(item.startTime)
                    this.dateEndTime2 = moment(item.endTime)
                    if (item.endTime == '4070880000000') {
                        this.langTimeTwo = true;
                    }
                }

            })
        }
        // if (this.formData.startTime && this.formData.endTime) {
        //     let startDate = moment(this.formData.startTime)
        //     let endDate = moment(this.formData.endTime)
        //     this.dateValue = [startDate, endDate]
        //     this.dateStartTime = startDate
        //     this.dateEndTime = endDate
        //     if (this.formData.endTime == '4070880000000') {
        //         this.langTime = true;
        //     }
        // }
        this.getQiniuToken()
    },
    methods: {
        isImportChange() {
            delete this.oneObj.startTime
            delete this.oneObj.endTime
            delete this.oneObj.fileUrl
            this.dateStartTime = null
            this.dateEndTime = null
            delete this.twoObj.startTime
            delete this.twoObj.endTime
            delete this.twoObj.fileUrl
            this.dateStartTime2 = null
            this.dateEndTime2 = null
            this.langTime = false
            this.langTimeTwo = false
            this.fileList = []
            this.fileList2 = []
        },
        onStartChange(date, dateString) {
            this.dateStartTime = date;
            if(dateString) {
                this.oneObj.startTime = dateString + ' 00:00:00'
            }else {
                this.oneObj.startTime = ""
            }
        },
        onStartChangeTwo(date, dateString) {
            this.dateStartTime2 = date;
            if(dateString) {
                this.twoObj.startTime = dateString + ' 00:00:00'
            }else {
                this.twoObj.startTime = ""
            }
        },
        onEndChangeTwo(date, dateString) {
            this.dateEndTime2 = date;
            if(dateString) {
                this.twoObj.endTime = dateString + ' 23:59:59'
            }else {
                this.twoObj.endTime = ""
            }
        },
        onEndChange(date, dateString) {
            this.dateEndTime = date;
            // this.oneObj.endTime = dateString + ' 23:59:59'
            if(dateString) {
                this.oneObj.endTime = dateString + ' 23:59:59'
            }else {
                this.oneObj.endTime = ""
            }
        },
        onLangTime(e) {
            this.langTime = e.target.checked
        },
        onLangTimeTwo(e) {
            this.langTimeTwo = e.target.checked
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                this.$message.error('上传文件类型有误!');
            }
            const isLt30M = file.size / 1024 / 1024 < 30;
            if (!isLt30M) {
                this.$message.error('Image must be smaller than 30MB!');
            }
            return isJpgOrPng && isLt30M;
        },
        handleChange(info) {
            const { fileList } = info;
            if (fileList.length > 0 && fileList[0].status === 'done') {
                console.log('上传成功:', fileList[0].response);
                this.$set(this.oneObj, 'fileUrl', fileList[0].response.url)
            }
            // if (info.file.status !== 'uploading') {
            //     this.oneObj.fileUrl = info.file.response.url;
            //     // this.$set(this.qualificationFileList[0].fileUrl, 'fileUrl', info.file.response.url)
            // }
            // if (info.file.status === 'done') {
            //     this.$message.success(`${info.file.name} 上传成功`);
            // } else if (info.file.status === 'error') {
            //     this.$message.error(`${info.file.name} 上传失败，请刷新页面重试`);
            // }
        },
        handleTwoChange(info) {
            const { fileList } = info;
            if (fileList.length > 0 && fileList[0].status === 'done') {
                console.log('上传成功:', fileList[0].response);
                this.$set(this.twoObj, 'fileUrl', fileList[0].response.url)
            }
            // if (info.file.status !== 'uploading') {
            //     this.twoObj.fileUrl = info.file.response.url;
            //     // this.$set(this.qualificationFileList[1].fileUrl, 'fileUrl', info.file.response.url)
            // }
            // if (info.file.status === 'done') {
            //     this.$message.success(`${info.file.name} 上传成功`);
            // } else if (info.file.status === 'error') {
            //     this.$message.error(`${info.file.name} 上传失败，请刷新页面重试`);
            // }
        },
        getQiniuToken() {
            if (!this.getQiniuTokenIng) {
                this.getQiniuTokenIng = true
                this.loading = true
                this.loadingText = 'token加载中'
                request(process.env.VUE_APP_API_BASE_URL + 'srm/qiniu/token/yjhp-hb', 'get').then((res) => {
                    if (res.data.code == 200) {
                        this.postData.token = res.data.data
                        // this.postData.key = 'sfa/upload' + Math.floor(Math.random() * 100) + new Date().valueOf()
                        this.getQiniuTokenIng = false
                    }
                })
            }
        },
        onChange(value) {
            console.log(value);
        },
        removeFile(type) {
            if (type == 0) {
                this.oneObj.fileUrl = ""
                this.fileList = []

            } else {
                this.twoObj.fileUrl = ""
                this.fileList2 = []
            }
        },
        handleCancel() {
            // 处理取消操作
            this.$emit('cancel');
        },
        handleSubmit() {
            this.formData.qualificationFileList = []
            console.log(this.formData,'this.formData')
            if (!this.formData.name) {
                this.$message.warning('请输入商品编码')
                return false;
            }
            if (this.formData.isImport == 0) {
                if (!this.oneObj.fileUrl) {
                    this.$message.warning('请上传产品质检报告')
                    return false;
                }
                // 长期有效
                // if (this.langTime) {
                //     this.oneObj.endTime = "2099-1-1 00:00:00"
                // }
                if (!this.oneObj.startTime || !this.oneObj.endTime) {
                    this.$message.warning('请选择有效期');
                    return false;
                }
                this.oneObj.certificateType = 401
                this.formData.qualificationFileList.push(this.oneObj)
            }
            if (this.formData.isImport == 1) {
                if (this.oneObj.fileUrl || this.oneObj.startTime || this.oneObj.endTime) {
                    if (!this.oneObj.fileUrl) {
                        this.$message.warning('请上传入境检验检疫证明')
                        return false;
                    }
                    if (!this.oneObj.startTime || !this.oneObj.endTime) {
                        this.$message.warning('请选择有效期');
                        return false;
                    }
                    this.oneObj.certificateType = 403
                    this.formData.qualificationFileList.push(this.oneObj)
                }
                if (!this.twoObj.fileUrl || !this.twoObj.startTime || !this.twoObj.endTime) {
                    if (!this.twoObj.fileUrl) {
                        this.$message.warning('请上传报关单')
                        return false;
                    }
                    if (!this.twoObj.startTime || !this.twoObj.endTime) {
                        this.$message.warning('请选择有效期');
                        return false;
                    }
                } else {
                    this.twoObj.certificateType = 402
                    this.formData.qualificationFileList.push(this.twoObj)
                }
                console.log(this.twoObj,'this.twoObj')
            }
            console.log(this.formData.qualificationFileList,'this.formData.qualificationFileList')
            if(this.formData.qualificationFileList.length == 0) {
                this.$message.warning('请至少上传完整一项')
                return false;
            }
            this.confirmLoading = true; 

            this.$emit('submit', this.formData)
            this.confirmLoading = true;
        },
        // handleSubmit() {
        //     this.formData.qualificationFileList = []
        //     if (!this.formData.name) {
        //         this.$message.warning('请输入商品编码')
        //         return false;
        //     }
        //     if (this.formData.isImport == 0) {
        //         if (!this.oneObj.fileUrl) {
        //             this.$message.warning('请上传产品质检报告')
        //             return false;
        //         }
        //         // 长期有效
        //         if (this.langTime) {
        //             this.oneObj.endTime = "2099-1-1 00:00:00"
        //         }
        //         if (!this.oneObj.startTime || !this.oneObj.endTime) {
        //             this.$message.warning('请选择有效期');
        //             return false;
        //         }
        //         this.oneObj.certificateType = 401
        //         this.formData.qualificationFileList.push(this.oneObj)
        //     }
        //     if (this.formData.isImport == 1) {
        //         if (!this.oneObj.fileUrl && !this.oneObj.startTime && !this.oneObj.endTime && !this.twoObj.fileUrl && !this.twoObj.startTime && !this.twoObj.endTime) {
        //             this.$message.warning('至少上传完整一条')
        //             return false;
        //         }
        //         if (this.oneObj.fileUrl && this.oneObj.startTime && this.oneObj.endTime) {
        //             this.oneObj.certificateType = 403
        //             this.formData.qualificationFileList.push(this.oneObj)
        //         }
        //         if (this.twoObj.fileUrl && this.twoObj.startTime && this.twoObj.endTime) {
        //             this.twoObj.certificateType = 402
        //             this.formData.qualificationFileList.push(this.twoObj)
        //         }
        //         if (!this.oneObj.fileUrl) {
        //             this.$message.warning('请上传入境检验检疫证明')
        //             return false;
        //         }

        //         if (!this.oneObj.startTime || !this.oneObj.endTime) {
        //             this.$message.warning('请选择有效期');
        //             return false;
        //         }
        //         this.oneObj.certificateType = 403
        //         this.formData.qualificationFileList.push(this.oneObj)


        //         if (!this.twoObj.fileUrl) {
        //             this.$message.warning('请上传报关单')
        //             return false;
        //         }

        //         if (!this.twoObj.startTime || !this.twoObj.endTime) {
        //             this.$message.warning('请选择有效期');
        //             return false;
        //         }
        //     }
        //     this.confirmLoading = true;

        //     this.$emit('submit', this.formData)
        //     this.confirmLoading = true;
        // },
    }
};
</script>
<style>
.title-text {
    font-weight: 700;
    font-size: 16px;
    color: #333;
}
</style>